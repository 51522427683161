#analysis-page{
  margin-inline: auto;
}

@media only screen and (min-width: 768px) {
  #analysis-page{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". analysis";
    max-width: 1140px;
  }
  #analysis-container{
    grid-area: analysis;
  }
}