.advanced-details-field {
  padding-top: 2em;
}

.sub-questions .question-container.inline {
  display: flex;
}
.wr-pwd-field {  
  .form-control {
    &:disabled, 
    &[readonly] {
      background-color: #e9eaeb;
      pointer-events: none;
    }
  }
}
.btn-back {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  color: #353435;
  padding: 0.375rem 0.05rem;
  box-shadow: none;
  margin-bottom: 2rem;
  &:hover,
  &:focus,
  &:active {
    color: #553193;
    outline: none;
    box-shadow: none;
  }
}

.footer-section {
  margin-top: 4rem;
  .login-details {
    .form-group {
      margin-top: 3rem;
      &.wr-pwd-field {
        position: relative;
        .btn {
          &.show-password {
            position: absolute;
            right: 0;
            bottom: 0;
            text-decoration: underline;
            border-bottom: none;
            height: 58px;
            margin-right: 0;
            color: #353435;
            &:hover,
            &:focus,
            &:active,
            &.active {
              color: #000000;
              border-radius: 0;
            }
            @media only screen and (max-width: 576px) {
              display: initial;
              width: inherit;
              margin-bottom: inherit;
            }
          }
        }
      }
    }
    @media screen and (max-width:640px) {
      label{
        font-size: 20px;
        line-height: 30px;
      }
      .form-control:not([type="checkbox"]){
        font-size: 12px;
      }
      .wr-pwd-field{
        input{
          padding-right: 60px;
        }
        .show-password{
          font-size: 12px;
        }
      }
      .error-text{
        font-size: 13px;
      }
    }
  
    @media screen and (max-width:440px) {
      .wr-pwd-field{
        input{
          padding: 0.375rem 0.75rem;
        }
        .show-password{
          top: -15px;
          padding: 0;
        }
      }
    }
  }
  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
  }
}
