@use "sass:math";

@font-face {
  font-family: "AndesNeue";
  font-weight: 800;
  src: url("Assets/fonts/andes/AndesNeue Bold.woff2") format("woff2");
}
@font-face {
  font-family: "AndesNeue";
  font-weight: 400;
  src: url("Assets/fonts/andes/AndesNeue Medium.woff2") format("woff2");
}
@font-face {
  font-family: "AndesNeue";
  font-weight: 300;
  src: url("Assets/fonts/andes/AndesNeue Light.woff2") format("woff2");
}

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

$main-font-family: "AndesNeue", "Times New Roman", sans-serif;
$optional-font-family: "Rubik", sans-serif;

$background01: #e9eaeb;
$background02: #f8f8f8;
$black: #000000;
$black01: #353435;
$black02: #263238;
$white-color: #ffffff;
$yellow-color: #ebab21;
$orange-color: #f36c25;
$rubyred-color: #e5344e;
$earth-color: #a04923;
$purple-color: #af3192;
$darkpurple-color: #553193;
$teal-color: #00646b;
$green-color: #05a189;

$transition: 0.5s;
$size:40px;

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;

html {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding-top: 100px;
  padding-bottom: 60px;
  font-family: $main-font-family;
  background-color: $background02;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $main-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $main-font-family;
}

//colors
.error-text {
  color: red;
  padding-top: 0.5em;
  font-family: $optional-font-family;
  font-size: 1rem;
  display: block;
}

.bg-e9eaeb {
  background-color: $background01;
}

//common font styles
.text-1 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
}

.text-2 {
  font-size: 1.25rem;
  font-weight: 400;
}

.text-3 {
  font-weight: 300 !important;
  font-size: 20px !important;
  font-family: $optional-font-family !important;
}

.text-4 {
  font-weight: 400;
  font-size: 16px;
  font-family: $optional-font-family !important;
}

.text-5 {
  font-weight: 300 !important;
  font-size: 20px !important;
}

.text-6 {
  font-weight: 300 !important;
  font-size: 1.1rem !important;
  font-family: $optional-font-family !important;
}

.head-1 {
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.2;
}

.head-2 {
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 1.2;
}

.header {
  margin-bottom: 3rem;
}
.subtext {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.option-group-item {
  padding-left: 1.5em !important;
}

@media screen and (max-width: 767px) {
  .header {
    margin-bottom: 2.2rem;
  }
}

%message-shared {
  &:focus {
    box-shadow: none;
  }
}

//nav-bar styles
.navbar-area {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  padding-top: 10px;
  padding-bottom: 10px;

  .navbar-toggler {
    border: none;
    padding: 10px 5px;     
    border-radius: 0;
    background-color: transparent;
    border: none;
    .navtoggle-text {
      display: inline;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      color: $black01;
      letter-spacing: 0.05em;
      font-family: $main-font-family;
    }
    &:hover,
    &:active,
    &.active {
      border-bottom: 3px solid $black;
      color: $black;
      box-shadow: none;
    }
    &:focus {
      outline: none; 
      border: 3px solid $black;
    }
    &.collapsed {
      &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }

  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }

  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
    max-width: 100%;
  }

  .btn-light {
    &.button-outline {
      span {
        position: relative;
        &::after{
          content: '';
          position: absolute;
          height: 2px;
          background-color: $black;
          width: 0;    
          left: 0;
          bottom: 0;
          transition: all 0.3s;
        }
      }
      &:hover,
      &:focus,
      &:active,
      &.active {
        opacity: 1;
        span {
          position: relative;
          color: $black;
          &::after{
            width: 100%;   
          }
        }
      }
    }
  }
}

//button styles
.btn-primary,
.btn-secondary {
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  transition: $transition;
  border-radius: 40px;     
  padding: 7px 25px; 
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font: {
    weight: 700;
    size: 16px;
  }
  &:hover {
    border-color: $white-color !important;
    background-color: $white-color !important;
    color: $black;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: 0.4s ease-in;
  }
}

.btn-primary {
  background-color: $darkpurple-color !important;
  border: 2px solid $darkpurple-color !important;
  color: $background01;
  &:hover {
    border-color: $darkpurple-color !important;
    background-color: $darkpurple-color !important;
    opacity: 0.7;
    color: $white-color;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: 0.4s ease-in;
  }
  &:disabled,
  &.disabled {
    background-color: $background01 !important;
    border-color: $background01 !important;
    color: $black01;
  }
}

.btn-secondary {
  background-color: transparent !important;
  border: 2px solid $black01 !important;
  color: $black01 !important;
  &:hover {
    border-color: $black01 !important;
    background-color: $black01 !important;
    color: $white-color !important;
  }
  &:focus { 
    border: none;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
    outline: none;
  }
}

.force-active{
  border-bottom: 3px solid $black !important;
  color: $black !important;
  box-shadow: none !important;
}

.btn-light {
  &.button-outline,
  &.pill-button {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.05em;
    color: $black01;  
    font: {
      weight: 700;
      size: 16px;
    }
    &.logout{
      &:hover{
        border-color: #e9eaeb !important;
        background-color: #fff !important;
        color: #000 !important;
        box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08) !important;
        transition: .4s ease-in !important;
        opacity: 1 !important;
      }
    }
  }
  &.button-outline {      
    border-radius: 0;
    background-color: transparent;
    border: none;
    margin-right: 10px;
    span {
      padding: 10px 5px;
    }
    &:hover,
    &:focus,
    &:active,
    &.active {
      opacity: 1;
      span {
        color: $black;
      }
    }
    &:focus{
      box-shadow: none;
    }
    &.login {   
      padding: 8px 22px;
      margin-right: 10px;
      span {
        padding: 8px 0;
        &:hover,
        &:focus,
        &:active,
        &.active {
          border-bottom: 3px solid $black;
          color: $black;
        }
      } 
      &:hover,
      &:focus,
      &:active,
      &.active {
        border-bottom: none;
      }
      &:focus {
        border-radius: 30px;    
        border: none;
        box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
      }
    }
  }
  &.pill-button {
    background: $background02;
    border: 1px solid $black01;
    border-radius: 30px;       
    padding: 8px 22px;     
    &:focus,
    &:active,
    &.active {
      box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);    
    }
    &:hover {
      border-color: #e9eaeb;
      background-color: $white-color;
      color: $black;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      transition: 0.4s ease-in;
    }
  }
}
.modal {
  .modal-footer {
    button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}

//intangible dots styles

%intangible-dots {
  ul {
    list-style: none;
    padding: 0px 0px 0px 0px;
    margin: 0;
  }
  li {
    letter-spacing: 0.1px;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    a {
      text-decoration: none;
      color: var(--sub-section-color);
      span {
        margin-bottom: -3px;
      }
    }
    span {
      display: inline-block;
      position: relative;
      height: 30px;
      width: 30px;
      margin-right: 20px;
      border-radius: 50%;
      border: 2px solid var(--sub-section-color);
    }

    &.complete span {
      background-color: var(--sub-section-color);
    }
  }
}

//common radio button and checkbox styles
.form-check-label {
  margin-top: 4px;
  margin-left: 9px;
}
.form-check-inline {
  margin-right: 1.375rem;
}
.form-check-input:checked[type="checkbox"] {
  background-color: $black01;
  border: 1.5px solid $black01;
}
.form-check-input:checked[type="radio"] {
  background-image: none;
  background-color: $black01;
  border: 1.5px solid $black01;
}
.form-check-input[type="checkbox"] {
  margin-top: 0em;
}
.form-check-input[type="radio"],
.form-check-input[type="checkbox"] {
  width: 24px;
  height: 24px;
  position: absolute;
  background-color: $background01;
  border: 1.5px solid $white-color;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.form-check-input:focus {
  border-color: #86b7fe !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}
//common form styles
.form-gap {
  row-gap: 1.2em;
}

.form-control {
  background-color: $background01;
  border-radius: 5px;
  border: none;
  font-weight: 300;
  font-size: 18px;
  font-family: $optional-font-family;
  line-height: 46px;
  color: $black01;
  &:-internal-autofill-selected {
    background-color: $background01 !important;
    color: $black01 !important;
  }
}

.toast-container {
  z-index: $zindex-popover;
}

//profile set up page
.profile-set-up-page {
  ._link_ {
    color: $black01;
  }
}
.profile-set-up-page,
.verify-email-page {
  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
}

//dropdown
.MuiPopover-root {
  .MuiPaper-root {
    background-color: $background02;
    max-height: 250px;
  }
}

//slider
.MuiSlider-root {
  color: $black01;
  .MuiSlider-mark {
    color: $background01;
  }
  .MuiSlider-rail {
    background-color: $black01;
    opacity: 1;
    border-radius: 3px;
    height: 1px;
  }
  .MuiSlider-thumb,
  .MuiSlider-track {
    background-color: $black01;
  }
  .MuiSlider-track {
    border-color: $black01;
  }
}

//text-area-field
.question-field-container.textarea {
  .form-control {
    background-color: $white-color;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.25);
    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
    }
  }
}

//question field container
.question-field-container {
  //input-field
  .MuiSelect-root {
    background-color: $background01;
    fieldset {
      border: none;
    }
    .MuiBox-root {
      .MuiButtonBase-root {
        background-color: $black01;
        color: $white-color;
        position: relative;
        padding-right: 10px;
        .MuiChip-label {
          &::before {
            content: url("./Assets/x-button.png");
            position: absolute;
            right: 15px;
            top: 7px;
          }
        }
        .MuiChip-deleteIcon {
          opacity: 0;
        }
      }
    }
  }
  .MuiInputLabel-root {
    &.MuiInputLabel-shrink {
      bottom: -40px;
      top: initial;
    }
  }
  //svg
  .MuiAutocomplete-popupIndicator {
    .MuiSvgIcon-root {
      display: inline-block;
      content: "";
      height: 15px;
      width: 15px;
      margin-right: 12px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: all 275ms ease-in-out;
      path {
        display: none;
      }
    }
  }

  .MuiSelect-iconOpen {
    transform: rotate(225deg);
    transition: all 275ms ease-in-out;
    margin-top: 6px;
  }
  .Mui-focused {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
  }

  //custom field
  .custom-file_input {
    .form-control {
      background-color: $white-color;
      border: 1px solid #ffffff;
      box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .btn-primary {
      background: #353435;
      opacity: 0.85;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      width: 150px;
      margin-left: 5px;
      text-transform: capitalize;
    }
  }

  .form-check label,
  em,
  .form-control {
    font-weight: 400;
    font-size: 16px;
    font-family: $optional-font-family !important;
    font-style: normal;
    color: $black01;
  }
}

//company profile page
.company-profile-page {
  #go-back-link {
    color: var(--bs-body-color);
  }

  #profile-company-name {
    .question-field-container {
      div.text{
        display: block !important;
      }
    }
  }

  #profile-earnings {
    .question-title {
      max-width: 42em;
    }
  }

  #profile-earnings,
  #profile-revenue {
    .question-container.inline {
      display: flex;
      align-self: center;
      align-items: center;
      margin-bottom: 1.25rem !important;
      .question-title {
        margin-right: 16px;
        margin-top: 0rem !important;
        width: 140px;
        font-size: 18px !important;
        font-family: $optional-font-family !important;
      }
    }
  }

  #profile-external-accounting {
    .btn {
      font-family: $optional-font-family !important;
      font-weight: 400;
    }
    .question-field-container {
      #profile-external-accounting.q-1 {
        .question-field-container.select {
          display: inline-block !important;
        }
      }
    }
  }

  #profile-governance-structure {
    .question-field-container {
      .form-check {
        margin-bottom: 14px;
      }
    }
  }

  #profile-vision {
    .question-field-container {
      .form-control {
        height: 252px !important;
      }
    }
  }
  .form-group {
    label {
      margin-bottom: 10px;
    }
    &.email {
      .form-control {
        border: 3px solid $background01;
        background-color: $background02;
        color: $black01;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

.company-profile-page, 
.question-page {
  .question-field-container {
    .text {      
      display: block !important;
    }
    &.currency {
      .input-group-text {
        background-color: $white-color;
        color: $black01;
      }
      .form-control {
        box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.25);
        border: 1px solid #ffffff;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
        }
      }
    }
  }
}

.question-page {
  .question-field-container.currency {
    max-width: 400px;
  }
  .button-section {
    float: right;
  }
}
//Account verification 
.verify-email-page {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 235px);
}
//Loader screen
.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 235px);

  @keyframes ldl-inner {
    0% {
      opacity: 1;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.5, 1.5);
    }

    100% {
      opacity: 0;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
    }
  }

  .ldl-inner div>div {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #6a6a6a;
    animation: ldl-inner 1s linear infinite;
  }

  .ldl-inner div:nth-child(1)>div {
    left: 75px;
    top: 45px;
    animation-delay: -0.8888888888888888s;
  }

  .ldl-inner>div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 80px 50px;
  }

  .ldl-inner div:nth-child(2)>div {
    left: 68px;
    top: 64px;
    animation-delay: -0.7777777777777778s;
  }

  .ldl-inner>div:nth-child(2) {
    transform: rotate(40deg);
    transform-origin: 73px 69px;
  }

  .ldl-inner div:nth-child(3)>div {
    left: 50px;
    top: 75px;
    animation-delay: -0.6666666666666666s;
  }

  .ldl-inner>div:nth-child(3) {
    transform: rotate(80deg);
    transform-origin: 55px 80px;
  }

  .ldl-inner div:nth-child(4)>div {
    left: 30px;
    top: 71px;
    animation-delay: -0.5555555555555556s;
  }

  .ldl-inner>div:nth-child(4) {
    transform: rotate(120deg);
    transform-origin: 35px 76px;
  }

  .ldl-inner div:nth-child(5)>div {
    left: 17px;
    top: 55px;
    animation-delay: -0.4444444444444444s;
  }

  .ldl-inner>div:nth-child(5) {
    transform: rotate(160deg);
    transform-origin: 22px 60px;
  }

  .ldl-inner div:nth-child(6)>div {
    left: 17px;
    top: 35px;
    animation-delay: -0.3333333333333333s;
  }

  .ldl-inner>div:nth-child(6) {
    transform: rotate(200deg);
    transform-origin: 22px 40px;
  }

  .ldl-inner div:nth-child(7)>div {
    left: 30px;
    top: 19px;
    animation-delay: -0.2222222222222222s;
  }

  .ldl-inner>div:nth-child(7) {
    transform: rotate(240deg);
    transform-origin: 35px 24px;
  }

  .ldl-inner div:nth-child(8)>div {
    left: 50px;
    top: 15px;
    animation-delay: -0.1111111111111111s;
  }

  .ldl-inner>div:nth-child(8) {
    transform: rotate(280deg);
    transform-origin: 55px 20px;
  }

  .ldl-inner div:nth-child(9)>div {
    left: 68px;
    top: 26px;
    animation-delay: 0s;
  }

  .ldl-inner>div:nth-child(9) {
    transform: rotate(320deg);
    transform-origin: 73px 31px;
  }

  .ldl-spinner {
    width: 80px;
    height: 80px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }

  .ldl-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.8);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
  }

  .ldl-inner div {
    box-sizing: content-box;
  }
}

//login page - Forgot pass page - Reset pass page
.login-page,
.forgot-pass-page,
.reset-pass-page {
  form {
    .div {
      background-color: $background01;
    }
  }
  .text-1 {
    margin-bottom: 3rem;
    font: {
      weight: 300;
      size: 48px;
    }
  }
  @media screen and (max-width:640px) {
    label{
      font-size: 20px;
      line-height: 30px;
    }
    .form-control:not([type="checkbox"]){
      font-size: 12px;
    }
    .wr-pwd-field{
      input{
        padding-right: 60px;
      }
      .show-password{
        font-size: 12px;
      }
    }
    .error-text{
      font-size: 13px;
    }
  }

  @media screen and (max-width:440px) {
    .wr-pwd-field{
      input{
        padding: 0.375rem 0.75rem;
      }
      .show-password{
        top: -30px !important;
        bottom: initial !important;
        padding: 0;
      }
    }
  }
}

.reset-pass-page,
.login-page {
  .form-check-label {
    margin-top: 0px;
    text-transform: none;
  }
  .remember-me label,
  #forgot-password-link {
    color: $black01;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 5px;
    outline: none;
    box-shadow: none;
    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $black;
      box-shadow: none;
      font-weight: 500;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
    }
  }
  .wr-remember-me {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .remember-me {
      .form-check {
        display: flex;
        align-items: center;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .wr-pwd-field {
    position: relative;
    .show-password {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      border: none;      
      color: $black01;
      margin-right: 0;
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $black;
        border-radius: 0;
      }
    }
  }
}

.reset-pass-page {
  .btn-link {
    color: var(--bs-body-color);
    padding: 0px;
  }
}

//admin page

.admin-page {
  h1,
  th,
  td {
    color: $black02 !important;
  }
  .btn-link {
    color: var(--bs-body-color);
    padding: 0px;
    &:hover {
      color: $white-color;
    }
  }
  .btn-outline-danger {
    padding: revert;
  }
  .pagination {
    justify-content: end;
    .page-item {
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 0.25rem;
    }
    .page-item.active .page-link {
      background-color: $black01 !important;
      border-color: $black01 !important;
      color: $white-color !important;
    }
    .page-link {
      color: $black01;
    }
  }
  .table {
    outline: 1px solid $background01 !important;
    th {
      font-size: 18px;
    }
    button {
      @extend %message-shared;
    }
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: $background01;
    --bs-table-accent-bg: none;
  }
  .table-hover {
    tbody {
      tr {
        &:hover {
          td {
            background-color: $black01 !important;
            color: $white-color !important;
            button {
              color: $white-color !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login-page {
    .seperator {
      display: none;
    }
    button {
      float: right;
      margin-top: 1.6em;
      &.show-password {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .question-page {
    .container {
      .sub-section {
        .sub-section-name {
          font-size: 1.199rem !important;
        }
      }
      .question-container {
        .question-title {
          font-size: 1.125rem !important;
        }
      }
    }
  }
  .complete-questionnaire-page {
    p.mail {
      font-size: 16px !important;
    }
  }
}

//question page
.question-page {
  .container {
    ul.section-nav-container {
      display:inline-block;
      list-style:none;
      @media screen and (max-width:767px) {
         padding-bottom: 20px;
         margin-left: -3px;
      }
      @media screen and (min-width:767px) {
        margin-left: -7px;
     }

      li.section-nav-item{
        cursor: initial;
        float: left;
        padding:$size*0.1;
        background-color: $background02;
        border-radius:$size;
        position:relative;
        margin-left: -30px;
        transition:all .2s;
        margin-top: 3px;

        a , div {
          overflow: hidden;
          border-radius:$size;
          transition:all .2s;
          text-decoration: none;
          height: $size;
          text-align: center;
          display: block;
          line-height: $size;
          padding-left: $size;
          padding-right: $size*0.5 +math.div($size, 3);
          background-color: $white-color;
          color: rgba(0, 0, 0, 0.33);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &:hover{
            color: $black01;
          }
        }
      }

      li.section-nav-item-status-Complete {
        a {
          background-color: var(--section-colour);
          color: $white-color !important;

          @media screen and (max-width:1199px) {
            background-color: var(--section-colour) !important;
          }
        }
      }

      li.section-nav-item-status-InProgress {
        a {
          color: var(--section-colour);
        }
      }
    }
    h1 {
      font-size: 1.875rem;
      font-weight: 400;
    }
    .section-description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 6%;
    }
    .section-dot {
      height: 1em;
      width: 1em;
      border-radius: 50%;
      margin-right: 0.7em;
    }
    .sub-section {
      .sub-section-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        word-wrap: break-word;
      }
      .question-field-container.number,
      .question-field-container.percentage {
        .form-control {
          box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.25);
          border: 1px solid $white-color;
          &:focus {
            box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
          }
        }
        .input-group-text {
          background-color: $white-color;
          color: $black01;
        }
      }
      .column-2 {
        .section-question {
          margin-top: 25px;
          &:nth-child(1) {
            margin-top: 0px;
          }
          .question-container {
            margin-top: 25px;
            &:nth-child(1) {
              margin-top: 0px;
            }
            .question-image-container {
              .question-image {
                @media screen and (max-width:767px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .question-page {
    .container {
      .sub-section {
        margin-bottom: 8%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .question-page {
    .container {
      .sub-section {
        margin-bottom: 18%;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .navbar-area .container-fluid {
    .navbar,
    .container {
      .nav-profile-button {
        margin-bottom: 10px;
        padding-left: 0px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .question-page {
    .container {
      .section-description {
        width: 55%;
      }
    }
  }
  .question-field-container {
    &.percentage {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}

//Resume Questionnaire page
.resume-questionnaire-page {
  .container {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-gap: 1rem 5rem;
      grid-template-areas:
        "title progress"
        "action progress";
    }
    .title-container {
      grid-area: title;
    }
    .progress-container {
      grid-area: progress;
      .assessments-status {
        margin-bottom: 30px;
        .available-assessments {          
        }
        .coming-soon {
          .assessment-item {
            .section-name {
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: #969696;
            }
          }
        }
        .assessment-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.75rem;
          >div {              
            display: flex;
            align-items: center;
          }
          svg {
            margin-right: 15px;
          }
          .button-outline {
            border-bottom: none !important;
            color: $darkpurple-color;
            &:hover,
            &:active {
              text-decoration: underline;
            }
          }
          @media only screen and (max-width: 768px) {
            .btn {
              display: initial;
              width: auto;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .action-container {
      grid-area: action;
    }
    h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding-bottom: 11px;
      margin-bottom: 30px;
      border-bottom: 2px solid $black;
    }

    @extend %intangible-dots;
  }
  .form-check {
    display: flex;
    align-items: center;
    .form-check-label {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 1400px) {
    .button-section {
      .btn {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .resume-questionnaire-page {
    .container {
      .content {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "title"
          "progress"
          "action";
      }
    }
  }
}

//complete Questionnaire page
.complete-questionnaire-page {
  a {
    color: $black01;
    text-decoration: none;
  }
  .intangibles {
    mix-blend-mode: multiply;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.finish-popup {
  div {
    padding-top: 15px;
    padding-bottom: 20px;
    p {
      padding-bottom: 20px;
    }
  
    @extend %intangible-dots;
 
    ul {
      li {
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
        a {
          span {
            margin-bottom: -5px;
          }
        }
        &:hover {
          background-color: rgb(240, 240, 240);
          border-radius: 10px;
          svg {
            color: gray;
          }
        }
        svg {
          margin-top: 7px;
        }
      }
    }
  }
}

//analysis page
.analysis-page {
  h1 {
    border-bottom: 1px solid $black01;
    color: $black02;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: $black;
  }
  a {
    text-decoration-line: underline;
  }
  h2 {
    font-weight: 400;
  }
  ul {
    padding-left: 0px;
  }
  ul li {
    list-style-type: none;
  }
  ul li:before {
    content: "-";
    font-weight: 500;
    width: 0.7em;
    display: inline-block;
  }
  p,
  ul li {
    font-weight: 300;
  }
  a,
  h2,
  ul li {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.1 px;
    color: $black02;
  }
}

//User profile page
.user-profile-page {
  .content {
    max-width: 55em;
    .profile-contents {
      .btn-link {
        color: #353435;
        padding: 0px;
        margin-bottom: 2rem;
        @extend %message-shared;
      }
    }
    .profile-detail-sub-question {
      display: flex;
      .profile-detail-sub-question-label {
        min-width: 115px;
      }
      .profile-detail-sub-question-value {
        margin-left: 20px;
      }
    }
    #profile-action-button-container {
      a , button {
        @extend %message-shared;
      }
    }
  }
  h5 {
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #263238;
  }
  #profile-details {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1px;
    color: $black02;
    .profile-detail-value {
      font-weight: 400;
      word-break: break-word;
    }
  }
  #profile-action-button-container {
    padding-top: 8rem;
    .btn-link,
    a {
      width: 100%;
      font-weight: 300;
      font-size: 18px;
      letter-spacing: 0.1px;
      color: $black02;
      text-align: start;
      padding-inline-start: 0;
    }
  }
}

.user-agreement-page {
  .container {
    .agreement-wrapper {
      max-width: 600px;
      max-height: 500px;
      padding-right: 20px;
      .logo-container {
        padding-top: 10px;
        padding-bottom: 20px;
        img {
          @media screen and (max-width:425px){
            width: 40%;
          }
          @media screen and (min-width:425px){
            width: 28%;
          }
        }
      }
      .content-container {
        font-weight: 100;
        p {
          font-size: 18px;
        }
        a {
          font-family: $optional-font-family;
          color: $black01;
          text-decoration: none;
          font-weight: 400;
          font-size: 16px;
          &:hover{
            text-decoration: underline;
          }
        }
        ol {
          list-style-type: none;
          li:before {
            content: "(" counter(section, lower-alpha) ") ";
            margin-left: -26px;
            margin-right: 5px;
          }
          li {
            counter-increment: section;
          }
        }

      }
    }
    .button-section {
      max-width: 600px;
    }
        /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $black01; 
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $black02; 
    }
  }
}

//footer
footer {
  font-family: $optional-font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.6);
  hr {
    background: rgba(0, 0, 0, 0.5);
    margin-bottom: 32px;
  }
}

//Responsive--------------------------------------
@media only screen and (max-width: 425px) {

  .form-gap {
    row-gap: 0.9em;
  }

  .company-profile-page , 
  .user-profile-page {
    .question-title , 
    .profile-detail-label {
      margin-top: 2.75rem;
    }
    #profile-user-name {
      .question-title {
        margin-top: 0;
      }
    }
  }

  .company-profile-page {
    #profile-earnings,
    #profile-revenue {
      .question-container.inline {
        .question-title {
          font-size: 14px !important;
        }
      }
    }
  }

  .login-page,
  .forgot-pass-page,
  .reset-pass-page {
    h1 {
      padding-bottom: 1.1em;
    }
  }
}

@media only screen and (max-width: 365px) {
  .resume-questionnaire-page {
    .container {
      .button-section {
        .btn {
          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 399px) and (max-width: 576px) {
  .resume-questionnaire-page {
    .container {
      .button-section {
        .btn {
          &:nth-child(2) {
            margin-left: 1em;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 425px) {
  .company-profile-page , 
  .user-profile-page {
    .question-title , 
    .profile-detail-label {
      margin-top: 2.75rem;
    }
    #profile-user-name {
      .question-title {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .start-page,
  .set-up-profile {
    .container {
      width: 80%;
    }
  }

  .profile-set-up-page,
  .verify-email-page {
    .container {
      width: 80%;
    }
  }

  .login-page,
  .forgot-pass-page,
  .reset-pass-page {
    .container {
      width: 80%;
    }
    h1 {
      padding-bottom: 2em;
    }
  }
  .question-field-container {
    &.percentage {
      width: 70%;
    }
  }
}

@media screen and (max-width: 550px) {
  .admin-page {
    .container {
      .admin-table {
        overflow-x: scroll !important;
        .table {
          width: 600px;
        }
      }
    }
  } 
}


@media only screen and (max-width: 576px) {
  //common
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 40px;
  }

  #profile-earnings,
  #profile-revenue {
    .question-field-container {
      .error-text {
        padding-top: 0.2em;
        padding-left: 0em;
      }
    }
    .inline.error {
      .question-title {
        margin-bottom: 22px !important;
      }
    }
  }
  .btn-primary,
  .btn-secondary,
  .button-outline,
  .pill-button{
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
    &.active {
      margin-bottom: 2rem;
    }
  }  
  .show-password {
    display: initial;
    width: auto;
    margin-bottom: 0;
  }
  .button-section {
    float: none !important;
    .btn {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 0 !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-section {
    .button-section {
      flex-direction: column-reverse;
      div {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        .btn-primary {          
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) {
  .button-section {
    .btn {
      &:nth-child(2) {
        margin-left: 1em;
      }
    }
  }

  #profile-earnings,
  #profile-revenue {
    .question-field-container {
      .error-text {
        padding-top: 0em;
        padding-left: 0.8em;
      }
      .input-group {
        margin-bottom: 0px !important;
      }
    }
    .inline.error {
      .question-title {
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  //common
  .container {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  //common
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .user-profile-page {
    .table {
      width: 70%;
    }
  }
  .question-field-container {
    &.percentage {
      width: 50%;
    }
  }
  .resume-questionnaire-page {
    .button-section {
      .btn {
        &:nth-child(2) {
          margin-left: 0em;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .company-profile-page {
    .question-field-container {
      
    }
  }
}

@media only screen and (max-width: 767px) {
  .company-profile-page {
    #profile-external-accounting {
      .question-container.inline {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .company-profile-page {
    #profile-external-accounting {
      .question-field-container {
        .question-container {
          .question-title {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .start-page,
  .set-up-profile {
    .container {
      width: 50%;
      padding-top: 120px;
      padding-bottom: 100px;
    }
  }

  .profile-set-up-page,
  .verify-email-page {
    padding-top: 80px;
    padding-bottom: 120px;
    .container {
      width: 50%;
    }
  }

  .resume-questionnaire-page,
  .complete-questionnaire-page,
  .user-profile-page,
  .user-agreement-page,
  .analysis-page,
  .admin-page {
    padding-top: 80px;
    padding-bottom: 160px;
  }

  .login-page,
  .forgot-pass-page,
  .reset-pass-page {
    .container {
      width: 50%;
      padding-top: 80px;
      padding-bottom: 120px;
    }
  }

  .question-page,
  .company-profile-page {
    .container {
      padding-top: 60px;
      padding-bottom: 80px;
    }
  }

  .company-profile-page {

    #profile-revenue {
      .form-control {
        width: 390px;
      }
    }

    #profile-external-accounting {
      .sub-question {
        display: flex;
        justify-content: flex-start;
      }
      .question-field-container {
        .question-container {
          .question-title {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
          }
        }
      }
    }

    #profile-vision {
      .question-field-container {
        .form-control {
          width: 68%;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar-area .container-fluid {
    padding-left: 28px;
    padding-right: 28px;
    .navbar,
    .container {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .navbar-collapse {
      padding-top: 20px;
      padding-bottom: 20px;
      button {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

@media only screen and (min-width: 991px) {
  .user-profile-page {
    .table {
      width: 50%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area .container-fluid {
    padding-left: 38px;
    padding-right: 38px;
  }

  .question-field-container {
    &.percentage {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 1199px) {
  body {
    padding-top: 0;
  }
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    z-index: $zindex-sticky;
    background-color: rgb(252 252 252) !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .navbar-area .container-fluid {
    max-width: 1200px;
    padding-left: 28px;
    padding-right: 28px;
  }
}

@media only screen and (min-width: 1200px) {
  .question-field-container {
    &.percentage {
      width: 30%;
    }
  }
}
