#main-nav {
  display: grid;
  grid-template-areas: "logo-container . profile-button logout-button";
  grid-template-columns: auto 1fr auto auto;
}

#logo-container {
  grid-area: logo-container;
  padding: 0;
  width: 140px;
  display: inline-block;
  margin-right: 1rem;
  white-space: nowrap;
}
#logo-container img {
  max-width: 100%;
  height: auto;
}

.nav-profile-button {
  grid-area: profile-button;
}

#nav-logout-button {
  grid-area: logout-button;
}
