.progress-bar-container{
  position: relative;
}
.progress-bar{
  height: 0.75em;
  background-color: var(--theme-color);
  position: absolute;
  border-radius: 1em;
}
  .progress-outer-bar{
  opacity: 0.6;
  width: 100%;
}
.progress-inner-bar{
  background-color: var(--theme-color);
  width: 50%;
}
.section-container {
  position: relative;
}
.button-section {
  @media screen and (max-width: 576px) {
    margin-top: 100px;
  }
}
.btn-back-to-db {
  position: absolute;
  bottom: 80px;
  right: 180px;
  @media screen and (max-width: 991px) {
    right: 185px;
  }
  @media screen and (max-width: 767px) {
    right: 12px;
    bottom: 60px;
  }
  @media screen and (max-width: 576px) {
    bottom: 40px;
    width: calc(100% - 40px);
  }
}
